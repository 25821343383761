<template>
  <div class="report-wraps">
    <!-- <div class="report-header">
      <img @click="back" class="back" src="../assets/images/back.png" alt="" />
      <p class="report-title">报告单</p>
    </div> -->
    <van-loading
      class="load"
      color="#1989fa"
      size="50"
      v-if="isLoading"
    ></van-loading>
    <div
      class="report-btn"
      v-for="item in baogaoList"
      :key="item.DocumentID"
      @click="baogaoDetail(item)"
    >
      <img class="report-icon-tag" src="../assets/images/tag@2x.png" />
      <div class="part">
        <div class="report-baogao">{{ item.DocumentTitle }}</div>
        <div class="report-text">{{ item.UpdateDate }}</div>
      </div>
      <img class="report-icon" src="../assets/images/xiangyou.png" />
    </div>
    <div class="empty" v-if="baogaoList.length === 0">
      <img
        src="https://hrp.weilaihos.com/uploads/20210121/98afe4a6068ee7b9cf59bbf25fb16377.png"
        alt=""
      />
      <text class="empty-text">暂无更多数据</text>
    </div>
  </div>
</template>

<script>
import { Dialog } from 'vant'
import { getFamilyOrderDetail, getDocument } from '../services/home'

export default {
  components: {
    [Dialog.Component.name]: Dialog.Component
  },
  data() {
    return {
      active: 0,
      baogaoList: [],
      visitNumber: '',
      empty: false,
      isLoading: true
    }
  },
  async created() {
    this.isLoading = true
    const now = new Date()
    function plus0(number) {
      return number < 10 ? '0' + number : number
    }
    const documentTypeArray = [
      '01001',
      '01002',
      '02001',
      '02002',
      '02003',
      '02004',
      '02005',
      '02006'
    ]
    const orderListRes = await getFamilyOrderDetail(
      '<Request>' +
        '<TradeCode>1104</TradeCode>' +
        '<ExtOrgCode></ExtOrgCode>' +
        '<ClientType></ClientType>' +
        '<HospitalId></HospitalId>' +
        '<ExtUserID>wechat002</ExtUserID>' +
        '<CardType></CardType>' +
        '<PatientCard></PatientCard>' +
        '<PatientID>' +
        this.$route.query.patientId +
        '</PatientID>' +
        '<StartDate>' +
        (now.getFullYear() - 1) +
        '-' +
        plus0(now.getMonth() + 1) +
        '-' +
        plus0(now.getDate()) +
        '</StartDate>' +
        '<EndDate>' +
        now.getFullYear() +
        '-' +
        plus0(now.getMonth() + 1) +
        '-' +
        plus0(now.getDate()) +
        '</EndDate>' +
        '<TransactionId></TransactionId>' +
        '<AdmNo></AdmNo>' +
        '</Request>'
    )

    if (orderListRes.data.Response.ResultContent === '没有查询到挂号记录') {
      this.isLoading = false
      return
    }

    const filterList = orderListRes.data.Response.Orders.Order.filter(
      e => e.Status === '正常'
    )

    if (filterList.length === 0) {
      this.isLoading = false
      return
    }

    const id = this.$route.query.patientId
    const queue = []

    filterList.forEach(async e => {
      const { AdmNo } = e
      documentTypeArray.forEach(async t => {
        const message =
          '<Request>' +
          '<Header>' +
          '<SourceSystem></SourceSystem>' +
          '<MessageID></MessageID>' +
          '</Header>' +
          '<Body>' +
          '<DocumentRetrievalRt>' +
          '<PATPatientID>' +
          id +
          '</PATPatientID>' +
          '<PAADMVisitNumber>' +
          AdmNo +
          '</PAADMVisitNumber>' +
          '<DocumentType>' +
          t +
          '</DocumentType>' +
          '<StartDate></StartDate>' +
          '<EndDate></EndDate>' +
          '<DocumentFormat></DocumentFormat>' +
          '</DocumentRetrievalRt>' +
          '</Body>' +
          '</Request>'
        queue.push(
          new Promise((resolve, reject) => {
            try {
              getDocument(encodeURIComponent(message)).then(res => {
                res.data.AdmNo = AdmNo
                res.data.t = t
                resolve(res)
              })
            } catch (error) {
              reject(error)
            }
          })
        )
      })
    })

    const resList = await Promise.all(queue)
    resList.forEach(tRes => {
      if (tRes.data.Response.Body.ResultCode === '0') {
        const { AdmNo, t } = tRes.data
        const tData =
          tRes.data.Response.Body.DocumentRetrievalRp.Documents.Document
        tData.AdmNo = AdmNo
        const tDataList = Array.isArray(tData) ? tData : [tData]
        this.baogaoList = [...this.baogaoList, ...tDataList]
        localStorage.setItem('visitNumber', AdmNo)
        localStorage.setItem(' documentType', t)
      }
    })

    this.isLoading = false
    // let orderList = null
    // if (orderListRes.data.Response.ResultCode === '0') {
    //   orderList = orderListRes.data.Response.Orders.Order
    // }

    // orderList = Array.isArray(orderList) ? orderList : [orderList]

    // // console.log(orderList)

    // for (const j in orderList) {
    //   // console.log(orderList[j].AdmNo, 'adm')
    //   for (let i = 0; i < documentTypeArray.length; i++) {
    //     const baogaoListRes = await getFamilyOrderDetailSelect(
    //       '<Request>' +
    //         '<Header>' +
    //         '<SourceSystem></SourceSystem> ' +
    //         '<MessageID></MessageID>' +
    //         '</Header>' +
    //         '<Body>' +
    //         '<DocumentRetrievalRt>' +
    //         '<PATPatientID>' +
    //         this.$route.query.patientId +
    //         '</PATPatientID>' +
    //         '<PAADMVisitNumber>' +
    //         orderList[j].AdmNo +
    //         '</PAADMVisitNumber> ' +
    //         '<DocumentType>' +
    //         documentTypeArray[i] +
    //         '</DocumentType>' +
    //         '<StartDate></StartDate>' +
    //         '<EndDate></EndDate>' +
    //         '<DocumentFormat>pdf</DocumentFormat>' +
    //         '</DocumentRetrievalRt>' +
    //         '</Body>' +
    //         '</Request>'
    //     )
    //     if (baogaoListRes.data.Response.Body.ResultCode === '0') {
    //       let baogaoListArr =
    //         baogaoListRes.data.Response.Body.DocumentRetrievalRp.Documents
    //           .Document
    //       baogaoListArr = Array.isArray(baogaoListArr)
    //         ? baogaoListArr
    //         : [baogaoListArr]
    //       const injectArr = baogaoListArr.map(e => {
    //         e.AdmNo = orderList[j].AdmNo
    //         return e
    //       })
    //       this.baogaoList = [...this.baogaoList, ...injectArr]
    //       console.log(injectArr)
    //       // console.log(orderList[j].AdmNo, '就诊号')
    //       // localStorage.setItem('visitNumber', orderList[j].AdmNo)
    //       // localStorage.setItem(' documentType', documentTypeArray[i])
    //     }
    //     // console.log(this.baogaoList.length, '11111')
    //     if (this.baogaoList.length === 0) {
    //       this.empty = true
    //     }
    //   }
    // }
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    baogaoDetail(item) {
      console.log(item)
      // localStorage.setItem('docid', docid)
      this.$router.push({
        name: 'baogaoDetail',
        query: {
          ...item,
          pid: this.$route.query.patientId
        }
      })
    }
  }
}
</script>

<style>
#app {
  min-height: auto !important;
}
.load {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.empty {
  height: 100vh;
  width: 100%;
  text-align: center;
  background: #ffffff;
  color: #909399;
}
.empty img {
  display: block;
  width: 375px;
  height: 293px;
}
.empty .empty-text {
  width: 100%;
  height: 25px;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 25px;
  position: absolute;
  top: 230px;
  left: 0px;
  text-align: center;
}

.report-wraps {
  background: #f9f9f9;
  height: 100vh;
  width: 100%;
  overflow-y: auto;
}

.report-header {
  height: 46px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: #5ca5ff;
  font-size: 16px;
  z-index: 100;
}
.part {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  line-height: 39px;
}
.report-btn {
  box-sizing: border-box;
  width: 92%;
  height: 60px;
  background: #ffffff;
  box-shadow: 0px 1px 2px 0px #eeeeee;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px auto;
  padding: 0 2% 0 10%;
  position: relative;
}
.report-baogao {
  width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  color: #333;
  font-size: 16px;
}
.report-text {
  display: block;
  /* flex: 1; */
  text-align: center;
}

.report-icon-tag {
  position: absolute;
  top: 10px;
  left: 0;
  width: 22px;
  height: 14px;
}

.report-icon {
  height: 16px;
  width: 16px;
}
</style>
